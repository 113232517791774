@import '../../styles/variables';

.ChallengeSelection {
  overflow: hidden;
  width: 100%;

  .swiper {
    aspect-ratio: 1600/2400;
    width: 70%;
    // height: 320px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    position: relative;
    border: 1px solid $theme-secondary-color;

    .challenge-label {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #fff;
      font-family: 'Eina-SemiBold';
      font-size: 20px;
      height: 100%;
      padding: 8px 16px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }
  }

  .swiper-slide {
    background-color: #dadada;
  }

  &-btn {
    $dimension: 64px;
    position: absolute;
    left: calc(50% - $dimension / 2);
    top: calc(50% - $dimension / 2);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-size: 16px;
    color: $golden-color;
    background-color: rgba($theme-secondary-color-rgb, 0.6);
  }
}
