@import '../../styles/variables';

.login {
  color: $yml-hb-white;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    background-repeat: no-repeat;
    height: 160px;
    background-image: url('../.././assets/logo.png');
    background-position: center;
    background-size: contain;
  }

  .login-btn-wrapper {
    text-align: center;
  }

  .loginBtn {
    background-color: $yml-hb-white;
    background-image: url('../../assets/google.svg');
    background-origin: content-box;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    color: $yml-hb-black;
    padding: 0 $padding;
    font-family: 'Eina-SemiBold';
  }

  .content {
    text-align: center;
  }

  .content-heading {
    font-size: 24px;
    margin-bottom: 24px;
    font-family: 'Eina-SemiBold';

    span {
      display: block;
    }
  }

  .content-description {
    font-family: 'Eina-Regular';
    font-size: 16px;
    margin-bottom: 16px;
  }

  .img-container {
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: min(min(50vh, 436px), 100%);
    margin: 8px auto 0;
  }

  $color-0: #fcdb7e;
  $color-1: #5da0f3;
  $color-2: #bac8d3;
  $color-3: #fff;
  $color-4: darkcyan;
  $color-5: burlywood;

  .item-0 {
    background-color: $color-0;
  }

  .item-1 {
    background-color: $color-1;
  }

  .item-2 {
    background-color: $color-2;
  }

  .item-3 {
    background-color: $color-3;
  }

  .item-4 {
    background-color: $color-4;
  }

  .item-5 {
    background-color: $color-5;
  }

  .text-0 {
    color: $color-0;
  }

  .text-1 {
    color: $color-1;
  }

  .text-2 {
    color: $color-2;
  }

  .text-3 {
    color: $color-3;
  }

  .text-4 {
    color: $color-4;
  }

  .text-5 {
    color: $color-5;
  }
}
