@import '../../../styles/variables';

.DashboardHeader {
  background: $app-bg-color;
  color: $dark-contrast-color;
  height: 64px;
  padding: 8px $padding;

  svg {
    stroke-width: 1.5;
  }

  .header-items {
    margin: 0;
  }

  .username {
    font-family: 'Eina-SemiBold';
    font-size: 16px;
    margin-left: 4px;
  }

  .tagName {
    display: block;
    font-size: 12px;
    font-family: 'Eina-SemiBold';
  }

  .profile-wrapper {
    border: 1px solid $golden-color;
    border-radius: 12px;
    padding: 4px;
  }

  .profile-img {
    border-radius: 8px;
    width: 32px;
    height: 32px;
  }

  .logo {
    aspect-ratio: 1/1;
    background-image: url('../../../assets/logo.png');
    background-size: contain;
    width: 88px;
    margin-right: 12px;
    background-position: 0px 4px;
    background-repeat: no-repeat;
  }

  .header-flex-items {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
