@import '../../styles/variables';

.Checkbox {
  display: flex;
  align-items: center;

  &-container {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid $theme-primary-color;
    display: inline-block;
    text-align: center;
  }

  &-input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &-label {
    margin-left: 8px;
  }

  &-input:checked ~ &-icon {
    display: inline-block;
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
}

.Checkbox.bg-light {
  .Checkbox-container {
    border: 1px solid $golden-color;
  }
}
