@import '../../styles/variables';

.Dashboard {
  &-title {
    text-align: center;
    margin: 24px 0;
    font-size: 18px;
    font-family: 'Eina-SemiBold';
  }

  .counter {
    display: none;
  }

  .CountDown-wrapper {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    color: $golden-color;
    font-family: 'SofiaSan-Medium';
    width: 100%;
    padding: 0 16px;

    .countdown-text {
      font-size: clamp(24px, 100vw * 24 / 375, 30px);
      font-family: 'SofiaSan-Medium';
      color: $golden-color;
      margin-bottom: 16px;
    }

    .custom-counter {
      display: flex;
      width: 100%;
      justify-content: space-around;

      .time-wrapper {
        width: 80px;
        padding: 8px;
        background-color: #fff;
        border-radius: 8px;
      }

      .time,
      .time-label {
        display: block;
        line-height: 1;
      }

      .time-label {
        font-size: 16px;
      }

      div {
        // background-color: $golden-color;
        color: $theme-primary-color;
      }
    }
  }
}
