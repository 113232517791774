.swiper {
  width: 60%;
  height: 200px;
  margin: 12px auto;

  img {
    object-fit: contain;
  }
}

.swiper-button {
  &-next,
  &-prev {
    color: #fff;
  }
}

.swiper-pagination-bullet {
  &-active {
    background: #fff;
  }
}

.Image-gallery-title {
  text-align: center;
  font-size: 18px;
  margin: 24px auto;
  font-family: 'Eina-SemiBold';
}
