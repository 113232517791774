@import '../../../styles/variables';

.BaseButton {
  width: 100%;
  font-family: 'Eina-SemiBold';
}

button.primary {
  background-color: $theme-primary-color;
  color: $theme-primary-contrast-color;
}

button.primary[disabled] {
  background-color: rgba($theme-primary-color-rgb, 0.4);
  color: rgba($yml-hb-white-rgb, 0.4);
}
button.secondary {
  color: $theme-primary-color;
  border: 1px solid $theme-primary-color;
  background-color: $theme-primary-contrast-color;
}

button.secondary[disabled] {
  border: 1px solid rgba($theme-primary-color-rgb, 0.4);
  color: rgba($theme-primary-color-rgb, 0.4);
}
