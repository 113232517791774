@import '../../styles/variables';

.ChallengeCalendar-wrapper {
  background-color: $yml-brand-grey;
  color: $theme-secondary-contrast-color;
  overflow: auto;
  padding: 16px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ChallengeCalendar {
  display: flex;
  width: 100%;

  &-item {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-family: SourceSansPro-Bold;
    height: 48px;
    justify-content: center;
    line-height: 1.2;
    margin: 0 4px;
    min-width: 36px;
    text-align: center;
    color: #fff;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    .day {
      font-size: 14px;
      text-transform: capitalize;
    }

    .date {
      font-size: 16px;
    }

    &.active {
      background-color: $dark-contrast-color;
      color: $theme-primary-color;
    }

    &.disabled {
      color: rgba(218, 218, 218, 0.3);
      pointer-events: none;
    }
  }
}
