@import '../../styles/variables';

.Leaderboard {
  &-heading {
    font-size: 28px;
    color: $yml-hb-white;
    margin: 12px 0 24px;
    text-align: center;
    font-family: 'Eina-SemiBold';
  }

  &-type {
    display: block;
    font-size: 18px;
    color: $golden-color;
  }

  &-item {
    display: flex;
    margin: 12px 0;
    border-radius: 12px;
    background: $yml-hb-bg;
    border-radius: 20px;
    padding: 8px $padding;
    align-items: center;
    color: $yml-hb-black;

    &.active-user {
      background-color: $yml-brand-grey;
      color: $theme-primary-contrast-color;

      .rank {
        color: $theme-primary-contrast-color;
      }
    }
  }

  &-items {
    padding: 0 $padding;
  }

  .profile {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 0 8px 1px $theme-primary-color;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .username {
    font-family: 'Eina-SemiBold';
    font-size: 18px;
  }

  .challenge {
    font-family: 'Eina-Regular';
    font-size: 12px;

    span {
      margin-right: 4px;
    }
  }

  .rank {
    // width:40px;
    font-family: 'Eina-SemiBold';
    font-size: 20px;
    text-align: center;
  }

  .progress {
    font-size: 14px;
  }
}
