@import '../../styles/variables';

.CheckInRecord {
  margin: 8px 0;
  padding: 0 16px;

  &-item {
    align-items: center;
    background-color: $yml-brand-grey;
    border-radius: 12px;
    color: #fff;
    display: flex;
    font-family: 'Eina-SemiBold';
    height: 64px;
    justify-content: space-between;
    margin: 8px 0;
    padding: 4px 8px;
    position: relative;
    width: 100%;
  }

  .checkIn-qty {
    color: $golden-color;
    font-family: 'Eina-SemiBold';
    font-size: 12px;
  }

  .checkIn-icon,
  .checkIn-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkIn-status {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #fff;
  }

  .checkIn-icon {
    height: 28px;
    width: 28px;
  }

  .checkIn-icon {
    background-repeat: no-repeat;
    background-size: 28px 28px;
    background-position: center;
  }

  .completed {
    background-color: #cbefcb;
  }

  .cheat-day {
    transform: rotate(45deg);
    background-color: #ebbaba;
  }

  .checkIn-content {
    margin-left: 16px;
    width: calc(100% - 32px * 2 - 16px);
  }

  .CheckInRecord-item-wrapper {
    &:disabled {
      background-color: rgba($disabled-rgba, 0.5);
    }
  }
}

.leaderboard-cta {
  margin: 24px auto;
  width: calc(100% - 32px);
  display: flex;

  button {
    width: 100%;
    margin: 0;
    padding: 0 32px;
    background-color: #fff;
    color: $yml-brand-green;
  }
}
.motivational_quote {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 18px;
  font-family: 'Eina-Regular';
  padding: 0 16px;
  margin: 24px auto;
}
