@font-face {
  font-family: SourceSansPro;
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: SourceSansPro-Medium;
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: SourceSansPro-SemiBold;
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: SourceSansPro-Bold;
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('/assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto-Bold;
  src: url('/assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: SofiaSans;
  src: url('/assets/fonts/SofiaSans-VariableFont_wght.ttf');
  font-weight: 400;
}

@font-face {
  font-family: SofiaSan-Medium;
  src: url('/assets/fonts/SofiaSans-VariableFont_wght.ttf');
  font-weight: 500;
}

@font-face {
  font-family: TiemposHeadline-Medium;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/TiemposHeadline-Medium.otf');
}

@font-face {
  font-family: Eina-Regular;
  font-weight: 400;
  src: url('/assets/fonts/Eina01-Regular/font.woff2') format('woff2'),
    url('/assets/fonts/Eina01-Regular/font.woff') format('woff');
}

@font-face {
  font-family: Eina-SemiBold;
  font-weight: 600;
  src: url('/assets/fonts/Eina01-SemiBold/font.woff2') format('woff2'),
    url('/assets/fonts/Eina01-SemiBold/font.woff') format('woff');
}
