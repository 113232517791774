@import '../../../styles/variables';

.modal-container {
  max-width: 450px;
}

.challenge-record-form {
  background-color: #fff;
  padding: 16px;
  color: $theme-primary-color;

  .challenge-name {
    text-transform: capitalize;
    font-family: 'Eina-SemiBold';
  }

  .input-control {
    position: relative;

    input,
    textarea {
      background-color: #ece7e7;
      border: none;
      font-family: 'Eina-SemiBold';
      font-size: 14px;
      width: 100%;
      padding: 4px 8px;
      height: 40px;
    }

    textarea {
      height: 60px;
    }

    label {
      font-size: 12px;
      margin-bottom: 4px;
      text-transform: capitalize;
    }

    button {
      background-color: $yml-brand-green;
    }

    .qty-info {
      text-transform: none;
    }
  }

  .input-lbl {
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 12px;
    /* line-height: 20px; */
    border: 1px solid grey;
    border-radius: 19px;
    width: 40px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    transform: translateY(-50%);
  }

  .close-btn {
    top: 0;
    right: 0;
    width: 36px;
  }

  .cheat-day-text {
    font-size: 14px;
    font-family: Roboto-Medium;
    color: $theme-primary-color;
  }

  .confirmation-checkbox {
    margin-bottom: 14px;
    font-family: 'Eina-SemiBold';
    font-size: 16px;
  }

  .confirmation-input {
    &-label {
      margin-left: 8px;
    }
  }

  .fileName {
    text-align: center;
    color: $theme-primary-color;
    font-size: 14px;
    margin-top: 4px;
    font-family: 'Eina-SemiBold';
  }
}
