@import '../styles/variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto';
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.App {
  background: $app-bg-color;
  box-shadow: 0 0 8px 4px $theme-primary-color;
  height: 100vh;
  margin: 0 auto;
  max-width: 500px;
  overflow: auto;
  position: relative;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background-color: unset;
  cursor: pointer;
  font-family: 'SourceSansPro-SemiBold';
  font-size: 18px;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SourceSansPro-Bold';
}

button {
  border-radius: 24px;
  height: 40px;
}

.link {
  text-decoration-line: underline;
  text-decoration-color: rgba($golden-color-rgb, 0.5);
  transition: text-decoration-color 250ms;
}

.link:hover {
  text-decoration-color: $golden-color-rgb;
}

.link.dark {
  text-decoration-color: rgba($yml-hb-black-rgb, 0.5);
}

.link.dark:hover {
  border-color: $yml-hb-black-rgb;
}

.running {
  background-image: url('/assets/challenge/running.svg');
}

.cycling {
  -webkit-mask-image: url('/assets/challenge/cycling.svg');
  mask-image: url('/assets/challenge/cycling.svg');
  background-color: $golden-color;
}

.gym {
  -webkit-mask-image: url('/assets/challenge/gym.svg');
  mask-image: url('/assets/challenge/gym.svg');
  background-color: $golden-color;
}

.sports {
  -webkit-mask-image: url('/assets/challenge/sports.svg');
  mask-image: url('/assets/challenge/sports.svg');
  background-color: $golden-color;
}

.reading {
  -webkit-mask-image: url('/assets/challenge/reading.svg');
  mask-image: url('/assets/challenge/reading.svg');
  background-color: $golden-color;
}

.yoga {
  -webkit-mask-image: url('/assets/challenge/yoga.svg');
  mask-image: url('/assets/challenge/yoga.svg');
  background-color: $golden-color;
}

.stretch {
  // -webkit-mask-image: url('/assets/challenge/stretch.svg');
  // mask-image: url('/assets/challenge/stretch.svg');
  background-image: url('/assets/challenge/stretch.svg');
}

.instrument {
  -webkit-mask-image: url('/assets/challenge/instrument.svg');
  mask-image: url('/assets/challenge/instrument.svg');
  background-color: $golden-color;
}

.art {
  -webkit-mask-image: url('/assets/challenge/art.svg');
  mask-image: url('/assets/challenge/art.svg');
  background-color: $golden-color;
}

.diet {
  -webkit-mask-image: url('/assets/challenge/diet.svg');
  mask-image: url('/assets/challenge/diet.svg');
  background-color: $golden-color;
}

.no_habit {
  -webkit-mask-image: url('/assets/challenge/no_habit.svg');
  mask-image: url('/assets/challenge/no_habit.svg');
  background-color: $golden-color;
}

.drink_water {
  -webkit-mask-image: url('/assets/challenge/drink_water.svg');
  mask-image: url('/assets/challenge/drink_water.svg');
  background-color: $golden-color;
}

.wake_up_early {
  -webkit-mask-image: url('/assets/challenge/wake_up_early.svg');
  mask-image: url('/assets/challenge/wake_up_early.svg');
  background-color: $golden-color;
}

.sleep_routine {
  -webkit-mask-image: url('/assets/challenge/sleep_routine.svg');
  mask-image: url('/assets/challenge/sleep_routine.svg');
  background-color: $golden-color;
}

.sunbath {
  -webkit-mask-image: url('/assets/challenge/sunbath.svg');
  mask-image: url('/assets/challenge/sunbath.svg');
  background-color: $golden-color;
}
