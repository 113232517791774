@import '../../styles/variables';

.ChallengesForm {
  .submit-cta-wrapper {
    min-height: 48px;
  }

  &-submit-cta-wrapper {
    width: unset;
    height: unset;
  }

  .submit-wrapper {
    text-align: center;
    padding: 0 16px;
    text-align: center;
  }

  .get-started-text {
    font-size: 16px;
    font-family: 'SourceSansPro-SemiBold';
    margin-bottom: 16px;
  }

  .checkbox-wrapper {
    justify-content: center;
  }

  .challenge-selection-heading {
    font-size: clamp(20px, 100vw * 20 / 375, 28px);
    margin: 16px 0;
    font-family: 'SofiaSan-Medium';
    text-transform: capitalize;
    padding: inherit 16px;

    span {
      display: block;
    }
  }

  .quote2 {
    text-align: center;
    font-size: 16px;
    font-family: 'SourceSansPro-SemiBold';
    margin-bottom: 24px;
  }

  .challenge-selection-condition {
    font-size: 14px;
    text-align: center;
    margin-bottom: 12px;
    color: $golden-color;
    font-family: 'SofiaSans';
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    justify-content: space-around;
  }

  .selected-challenges {
    display: flex;
    font-family: SourceSansPro-Bold;
    font-size: 14px;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 8px;
    padding: 0 32px;
    text-transform: capitalize;
    height: 33px;
    flex-wrap: wrap;

    span {
      margin: 4px;
      color: $theme-secondary-contrast-color;
      border-radius: 12px;
      background-color: $theme-secondary-color;
      height: 25px;
      display: inline-block;
      padding: 0 8px;
    }
  }

  .no-going-back-txt {
    text-align: left;
    margin: 4px 0 24px;
    font-size: 14px;
    font-family: 'SofiaSans';
  }
}