/* colors */
$yml-hb-bg: rgb(218, 218, 218);
$yml-hb-black: Black;
$yml-hb-black-rgb: rgb(0, 0, 0);
$yml-hb-white: #fff;
$yml-hb-white-rgb: rgb(255, 255, 255);
$yml-soft-magenta: hsl(300, 69%, 71%);
$yml-soft-magenta: hsl(300, 69%, 71%);
$yml-violet: #674baf;
$yml-violet: hsl(257, 40%, 49%);

/* styleguide colors */
$yml-brand-green: #134e30;
$yml-brand-grey: #4d4c51;

$dark-contrast-color: #fff;
$light-contrast-color: #384a3a;
$light-green-color: #aaf7b8;
$golden-color: #eac37b;
$golden-color-rgb: rgb(234, 195, 123);
$golden-disabled-color: rgba(234, 195, 123, 0.2);
$disabled: #dadada;
$disabled-rgba: rgb(218, 218, 218);

/* theme colors */
$theme-primary-color: #384a3a;
$theme-primary-color-rgb: rgb(56, 74, 58);
$theme-secondary-color: #5c605b;
$theme-secondary-color-rgb: rgb(92, 96, 91);
$theme-primary-contrast-color: $dark-contrast-color;
$theme-secondary-contrast-color: $dark-contrast-color;

/* gradient */
$app-bg-gradient: linear-gradient(
  27deg,
  rgba(0, 0, 0, 1) 0%,
  rgba(30, 43, 43, 1) 0%,
  rgba(32, 62, 60, 1) 0%,
  rgba(0, 0, 0, 1) 100%
);

/* app background */
// $app-bg-color: $yml-brand-green;
$app-bg-color: linear-gradient(27deg, $yml-brand-green 0%, Black 100%);

/* spacing */
$padding: 16px;
$breakpoint: 812px;

/* shadow */
$yml-hb-box-shadow: 0px 2px 8px rgba(13, 149, 233, 0.16);

$circle-sm-size: 40px;
